<template>
  <div>
    <div class="flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">CKEditor</h2>
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <!-- BEGIN: Simple Editor -->
      <div class="col-span-12 lg:col-span-6">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Simple Editor</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#simple-editor"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="simple-editor" class="p-5">
            <div class="preview">
              <CKEditor
                v-model="editorData"
                :editor="classicEditor"
                :config="simpleEditorConfig"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-simple-editor"
                class="copy-code button button--sm border flex items-center text-gray-700"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-simple-editor" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <CKEditor
                        v-model="editorData"
                        :editor="classicEditor"
                        :config="simpleEditorConfig"
                      />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Simple Editor -->
      <!-- BEGIN: Standard Editor -->
      <div class="col-span-12 lg:col-span-6">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Standard Editor</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#standard-editor"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="standard-editor" class="p-5">
            <div class="preview">
              <CKEditor
                v-model="editorData"
                :editor="classicEditor"
                :config="editorConfig"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-standard-editor"
                class="copy-code button button--sm border flex items-center text-gray-700"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-standard-editor" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <CKEditor v-model="editorData" :editor="classicEditor" :config="editorConfig" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Standard Editor -->
      <!-- BEGIN: Inline Editor -->
      <div class="col-span-12 lg:col-span-6">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Inline Editor</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#inline-editor"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="inline-editor" class="p-5">
            <div class="preview">
              <CKEditor
                v-model="editorData"
                :editor="inlineEditor"
                :config="editorConfig"
                class="border"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-inline-editor"
                class="copy-code button button--sm border flex items-center text-gray-700"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-inline-editor" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <CKEditor v-model="editorData" :editor="inlineEditor" :config="editorConfig" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Inline Editor -->
      <!-- BEGIN: Balloon Editor -->
      <div class="col-span-12 lg:col-span-6">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Balloon Editor</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#balloon-editor"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="balloon-editor" class="p-5">
            <div class="preview">
              <CKEditor
                v-model="editorData"
                :editor="balloonEditor"
                :config="editorConfig"
              />
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-balloon-editor"
                class="copy-code button button--sm border flex items-center text-gray-700"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-balloon-editor" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <CKEditor v-model="editorData" :editor="balloonEditor" :config="editorConfig" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Balloon Editor -->
      <!-- BEGIN: Document Editor -->
      <div class="col-span-12">
        <div class="box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
          >
            <h2 class="font-medium text-base mr-auto">Document Editor</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <div class="mr-3">Show example code</div>
              <input
                data-target="#document-editor"
                class="show-code input input--switch border"
                type="checkbox"
              />
            </div>
          </div>
          <div id="document-editor" class="p-5">
            <div class="preview">
              <div class="document-editor">
                <div
                  ref="document-editor-toolbar"
                  class="document-editor__toolbar"
                ></div>
                <div class="document-editor__editable-container">
                  <div class="document-editor__editable">
                    <CKEditor
                      v-model="editorData"
                      :editor="documentEditor"
                      :config="editorConfig"
                      @ready="initDocumentEditor"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="source-code hidden">
              <button
                data-target="#copy-document-editor"
                class="copy-code button button--sm border flex items-center text-gray-700"
              >
                <FileIcon class="w-4 h-4 mr-2" /> Copy example code
              </button>
              <div class="overflow-y-auto h-64 mt-3">
                <Highlight id="copy-document-editor" class="source-preview">
                  <code
                    class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
                  >
                    <textarea>
                      <CKEditor v-model="editorData" :editor="documentEditor" :config="editorConfig" />
                    </textarea>
                  </code>
                </Highlight>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Document Editor -->
    </div>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

export default {
  components: {
    CKEditor: CKEditor.component
  },
  data() {
    return {
      classicEditor: ClassicEditor,
      inlineEditor: InlineEditor,
      balloonEditor: BalloonEditor,
      documentEditor: DocumentEditor,
      simpleEditorConfig: {
        plugins: [
          ParagraphPlugin,
          BoldPlugin,
          UnderlinePlugin,
          ItalicPlugin,
          LinkPlugin
        ],
        toolbar: {
          items: ["bold", "italic", "underline", "link"]
        }
      },
      editorConfig: {
        cloudServices: {
          tokenUrl: "",
          uploadUrl: ""
        },
        plugins: [
          Font,
          EssentialsPlugin,
          BoldPlugin,
          UnderlinePlugin,
          StrikethroughPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          CodePlugin,
          SubscriptPlugin,
          SuperscriptPlugin,
          EasyImage,
          Heading,
          HeadingButtonsUI,
          Highlight
        ],
        toolbar: {
          items: [
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "code",
            "subscript",
            "superscript",
            "link",
            "undo",
            "redo",
            "imageUpload",
            "highlight"
          ]
        }
      },
      editorData: "<p>Content of the editor.</p>"
    };
  },
  methods: {
    initDocumentEditor(editor) {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer.appendChild(editor.ui.view.toolbar.element);
      window.editor = editor;
    }
  }
};
</script>
